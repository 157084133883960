<template>
  <div class="bigbox">
    <!-- 首页导航 -->
    <div class="zhfwtop">
      <div class="indeximgtopcontent">
         <div class="indexlogo"></div>
        <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span>产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <span  @click="go('/cpjjfa/zhcg')">智慧采购</span>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span class="active2">用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      <a href="http://www.kingislucky.cn:81/#/home" class="enbutton">
        <img src="../.././assets/enicon.png" alt="">
        <div>Translate</div>
      </a>
      </div>

      <div class="zhfwbutton"></div>
    </div>
    <!-- 经典案例 -->
    <div class="jdal">
      <div class="jdalcontent">
        <div class="jdalcontenttitle">
          <div class="hxystitleback"></div>
          <div class="hxystitl-ti">经典案例</div>
          <div class="hxystitleback2"></div>
        </div>
        <div class="jdalcontentbottom">
          <div @click="go('/yhal/zkd')" class="jdalcontentbottom1">
            <div class="jdalcontentbottom1top"></div>
            <div class="jdalcontentbottom1bottom">
              <div class="title">中国科学技术大学</div>
              <div class="line1"></div>
              <div class="induce">创业者平台升级开发不同，是学院的项目信息服务人员为企业提供产品及项目管理权限的产品...</div>
            </div>
          </div>
          <div @click="go('/yhal/dlsjy')" class="jdalcontentbottom2">
             <div class="jdalcontentbottom1bottom">
              <div class="title">省电力设计院</div>
              <div class="line1"></div>
              <div class="induce">省电力设计院能源信息统计与检测系统把各类共享信息资源的开发利用作为重点工作内容，这......</div>
            </div>
            <div class="jdalcontentbottom1top"></div>

          </div>
          <div @click="go('/yhal/csgx')" class="jdalcontentbottom3">
            <div class="jdalcontentbottom1top"></div>
            <div class="jdalcontentbottom1bottom">
              <div class="title">城市更新</div>
              <div class="line1"></div>
              <div class="induce">新型智慧城市”建设“六个一”建设理念即“一个体系架构、一张天地一体的栅格网、一个通......</div>
            </div>
          </div>
          <div @click="go('/yhal/gyyq')" class="jdalcontentbottom4">
             <div class="jdalcontentbottom1bottom">
              <div class="title">工业数字经济</div>
              <div class="line1"></div>
              <div class="induce">工业设计引擎将以“数字经济+工业设计”赋能浙江省乃至全国实体经济和制造业产业的高质.........</div>
            </div>
            <div class="jdalcontentbottom1top"></div>

          </div>
          <div @click="go('/yhal/gdyh')" class="jdalcontentbottom5">
            <div class="jdalcontentbottom1top"></div>
            <div class="jdalcontentbottom1bottom">
              <div class="title">光大银行</div>
              <div class="line1"></div>
              <div class="induce">流程自动化机器人系统，RPA应用规范建设，加速业务流程，降低运营成本，提高业务化能.........</div>
            </div>
          </div>
          <div @click="go('/yhal/jkjt')" class="jdalcontentbottom6">
             <div class="jdalcontentbottom1bottom">
              <div class="title">省交通投资控股集团</div>
              <div class="line1"></div>
              <div class="induce">各项战略交接项目通过为集团管理系统管理事务的信息化，以流程控制发展集团为管理系统提...</div>
            </div>
            <div class="jdalcontentbottom1top"></div>

          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../components/TabarBottom.vue'
  export default {
    name:'yhal',
    components: { TabarBottom },
     methods:{
       go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.enbutton{
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  right: 5%;
  top: 42px;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  width: 102px;
  height: 28px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
// 经典案例
.jdal{
  // width: 1903px;
  height: 700px;
  margin: 0 auto;
  .jdalcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
      .jdalcontenttitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 88px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('../.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('../.././assets/righticon.png') no-repeat center center;
  }
}
.jdalcontentbottom{
  width: 1200px;
  height: 398px;
  cursor: pointer;
  display: flex;
  .jdalcontentbottom1{
    width: 199px;
    height: 398px;

    .jdalcontentbottom1top{
      width: inherit;
      height: 215px;
      background: url('../.././assets/jdalcontentbottom1top.png');
    }
    .jdalcontentbottom1bottom{
      width: inherit;
      height: 183px;
      .title{
        padding-top: 20px;
        width: 144px;
        height: 18px;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #282A2E;
        margin: 0 auto;
      }
      .line1{
        width: 24px;
        height: 3px;
        line-height: 20px;
        background: #FFA000;
        border-radius: 100px 100px 100px 100px;
        opacity: 1;
        margin: 10px auto 20px auto;
      }
      .induce{
        width: 170px;
        height: 88px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #282A2E;
        margin: 0 auto;
      }
    }
  }
  .jdalcontentbottom2{
    width: 199px;
    height: 398px;

    .jdalcontentbottom1top{
      width: inherit;
      height: 215px;
      background: url('../.././assets/jdalcontentbottom2top.png');
    }
    .jdalcontentbottom1bottom{
      width: inherit;
      height: 183px;
      // text-align: center;
      background: #FFA000;
      .title{
        padding-top: 20px;
        width: 108px;
        height: 18px;
        margin: 0 auto;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
      .line1{
        width: 24px;
        height: 3px;
        background: #fff;
        border-radius: 100px 100px 100px 100px;
        opacity: 1;
        margin: 10px auto 20px auto;
      }
      .induce{
        width: 170px;
        height: 88px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin: 0 auto;
      }
    }
  }
  .jdalcontentbottom3{
    width: 199px;
    height: 398px;

    .jdalcontentbottom1top{
      width: inherit;
      height: 215px;
      background: url('../.././assets/jdalcontentbottom3top.png');
    }
    .jdalcontentbottom1bottom{
      width: inherit;
      height: 183px;
      .title{
        padding-top: 20px;
        width: 72px;
        height: 18px;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #282A2E;
        margin: 0 auto;
      }
      .line1{
        width: 24px;
        height: 3px;
        line-height: 20px;
        background: #FFA000;
        border-radius: 100px 100px 100px 100px;
        opacity: 1;
        margin: 10px auto 20px auto;
      }
      .induce{
        width: 170px;
        height: 88px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #282A2E;
        margin: 0 auto;
      }
    }
  }
  .jdalcontentbottom4{
    width: 199px;
    height: 398px;

    .jdalcontentbottom1top{
      width: inherit;
      height: 215px;
      background: url('../.././assets/jdalcontentbottom4top.png');
    }
    .jdalcontentbottom1bottom{
      width: inherit;
      height: 183px;
      // text-align: center;
      background: #FFA000;
      .title{
        padding-top: 20px;
        width: 108px;
height: 18px;
margin: 0 auto;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
      .line1{
        width: 24px;
        height: 3px;
        background: #fff;
        border-radius: 100px 100px 100px 100px;
        opacity: 1;
        margin: 10px auto 20px auto;
      }
      .induce{
        width: 170px;
        height: 88px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin: 0 auto;
      }
    }
  }
  .jdalcontentbottom5{
    width: 199px;
    height: 398px;

    .jdalcontentbottom1top{
      width: inherit;
      height: 215px;
      background: url('../.././assets/jdalcontentbottom5top.png');
    }
    .jdalcontentbottom1bottom{
      width: inherit;
      height: 183px;
      .title{
        padding-top: 20px;
        width: 72px;
        height: 18px;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #282A2E;
        margin: 0 auto;
      }
      .line1{
        width: 24px;
        height: 3px;
        line-height: 20px;
        background: #FFA000;
        border-radius: 100px 100px 100px 100px;
        opacity: 1;
        margin: 10px auto 20px auto;
      }
      .induce{
        width: 170px;
        height: 88px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #282A2E;
        margin: 0 auto;
      }
    }
  }
  .jdalcontentbottom6{
    width: 199px;
    height: 398px;

    .jdalcontentbottom1top{
      width: inherit;
      height: 215px;
      background: url('../.././assets/jdalcontentbottom6top.png');
    }
    .jdalcontentbottom1bottom{
      width: inherit;
      height: 183px;
      // text-align: center;
      background: #FFA000;
      .title{
        padding-top: 20px;
        width: 162px;
height: 18px;
font-size: 18px;
margin: 0 auto;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin: 0 auto;
      }
      .line1{
        width: 24px;
        height: 3px;
        background: #fff;
        border-radius: 100px 100px 100px 100px;
        opacity: 1;
        margin: 10px auto 20px auto;
      }
      .induce{
        width: 170px;
        height: 88px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin: 0 auto;
      }
    }
  }
}
  }
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../.././assets/yhalindexback.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
   padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
